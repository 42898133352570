import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Row, Col, Input, Card, DatePicker } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { loadTenant, submitPaymentAQWire, POST_PAYMENT_FULFILLED } from '../../actions'
import { QUICK_WIRE } from '../../../../../utilities/paymentsTypeEnum'
import { CREATED } from '../../../../../utilities/paymentsStatusEnum'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

const Dragonpay = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        props.onLoadTenant(getUser().id).then(res => {
            let { clientCode, soaDate, uoccname } = res.payload
            soaDate = moment(soaDate).format('MMDDYYYY')
            const name = uoccname
            const currency = 'PHP'
            const paymentType = 'OTHERS'
            const invoiceNumber = `(${clientCode})${soaDate}`
            const transactionDate = moment(new Date())
            form.setFieldsValue({ ...res.payload, invoiceNumber, transactionDate, name, currency, paymentType })
        })
    }, [])

    function handleSubmit(values) {
        let { clientCode } = props.tenantData
        const origin = window.location && window.location.origin
        const submitData = {
            tenantId: getUser().id,
            clientCode,
            transactionDate: values.transactionDate,
            invoiceDate: values.invoiceDate,
            soaAmount: values.soaAmount,
            amountPaid: values.amountPaid,
            invoiceNumber: values.invoiceNumber,
            type: QUICK_WIRE,
            status: CREATED,
            email: values.email,
            currency: values.currency,
            name: values.name,
            mobile: values.mobile,
            description: 'Payments',
            paymentType: values.paymentType,
            note: values.note,
            redirectUrlSuccess: `${origin}/billings/payments/aqwire/success?`,
            redirectUrlCancel: `${origin}/billings/payments/aqwire/cancel?`,
            redirectUrlCallback: `https://${REACT_APP_WEBSOCKET_URL}/api/payment-aqwire`

        }
        Modal.confirm({
            title: 'AQWire Payment Gateway',
            icon: <ExclamationCircleOutlined />,
            content: `You chose AQWire as your payment gateway, Continuing this will create a record to our payments data, also you will be redirected to AQWire Card Payment Page.`,
            onOk() {
                props.onSubmitPayment(submitData).then(res => {
                    console.log("RESULT", res)
                    if (res.type === POST_PAYMENT_FULFILLED) {
                        res.payload.paymentUrl
                            ? (window.open(res.payload && res.payload.paymentUrl, "_blank")) :
                            Modal.error({
                                title: 'An Error Occurred',
                                content: `AQWire says: ${res.payload && res.payload.message}`,
                            });
                    }
                })
            },
        });
    }

    return (
        <Card>
            <h1>AQWire</h1>
            <Form
                form={form}
                name="basic"
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Invoice Number (Read Only)"
                            name="invoiceNumber"
                        >
                            <Input className='primary-input' disabled />
                        </Form.Item>

                        <Form.Item
                            label="SOA Amount (Read Only)"
                            name="soaAmount"
                        >
                            <Input className='primary-input' disabled />
                        </Form.Item>

                        <Form.Item
                            label="Name (Read Only)"
                            name="name"
                        >
                            <Input className='primary-input' disabled />
                        </Form.Item>

                        <Form.Item
                            label="Transaction Date"
                            name="transactionDate"
                            rules={[{ required: true, message: 'Please input your end time!' }]}
                        >
                            <DatePicker
                                disabled
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}

                        >
                            <Input className='primary-input' placeholder='ex: johnDoe@outlook.com' />
                        </Form.Item>

                        <Form.Item
                            label="Mobile (Active)"
                            name="mobile"
                        // rules={[{ required: true, message: 'Please input your mobile!' }]}
                        >
                            <Input className='primary-input'  placeholder='ex: 09561238901' />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {/* <Form.Item
                            label="Description"
                            name="description"
                        // rules={[{ required: true, message: 'Please input your description!' }]}
                        >
                            <Input className='primary-input' />
                        </Form.Item> */}
                        <Form.Item
                            label="Note"
                            name="note"
                        // rules={[{ required: true, message: 'Please input your note!' }]}
                        >
                            <Input className='primary-input' />
                        </Form.Item>

                        <Form.Item
                            label="Paid Amount"
                            name="amountPaid"
                            rules={[{ required: true, message: 'Please input your Paid Amount!' }]}
                        >
                            <Input className='primary-input' type='number' placeholder='ex: 25000' />
                        </Form.Item>

                        <Form.Item
                            label="Currency"
                            name="currency"
                            rules={[{ required: true, message: 'Please input your Currency!' }]}
                        >
                            <Input className='primary-input' value='PHP' disabled />
                        </Form.Item>

                        <Form.Item
                            label="Payment Type"
                            name="paymentType"
                            rules={[{ required: true, message: 'Please input your payment type!' }]}
                        >
                            <Input className='primary-input' disabled />
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={props.paymentLoading || loading}>
                                CONTINUE WITH AQWIRE
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}


function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
        paymentLoading: state.client.billings.paymentLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
        onSubmitPayment: (data) => dispatch(submitPaymentAQWire(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dragonpay)
